<template>
    <article class="section">
        <div class="container">
            <header class="block">
                <div class="level">
                    <div class="level-left">
                        <h1 class="title">Label Dump</h1>
                    </div>
                </div>
            </header>
            <section class="block">
                   <div class="columns">
                        <div class="column is-3">
                            <b-field label="Vanaf">
                                <b-datepicker
                                    v-model="fromDate"
                                    placeholder="Selecteer vanaf datum"
                                    icon="calendar-today"
                                    :icon-right="fromDate ? 'close-circle' : ''"
                                    icon-right-clickable
                                    @icon-right-click="clearDate(type='from')"
                                    trap-focus>
                                </b-datepicker>
                            </b-field>
                        </div>
                        <div class="column is-3">
                            <b-field label="Tot">
                                <b-datepicker
                                    v-model="toDate"
                                    placeholder="Selecteer tot datum"
                                    icon="calendar-today"
                                    :icon-right="toDate ? 'close-circle' : ''"
                                    icon-right-clickable
                                    @icon-right-click="clearDate(type='to')"
                                    trap-focus>
                                </b-datepicker>
                            </b-field>
                        </div>
                        <div class="column is-3">
                            <b-field label="Organisatie Filter">
                                <b-input v-model="organisation" placeholder="vul organisatie id in"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-3">
                             <b-field label="Naar email">
                                <b-input v-model="email" type="email" placeholder="vul email adres in"></b-input>
                            </b-field>
                        </div>

                        <div class="column is-3">
                            <b-field label="Alleen retour labels">
                                <b-checkbox v-model="only_return"
                                    true-value="Alleen retour labels"
                                    false-value="Alle labels">
                                    {{ only_return }}
                                </b-checkbox>
                            </b-field>
                        </div>
                   </div>
            </section>
            <section>
                <b-button @click="submit" class="is-primary">┌( ͝° ͜ʖ͡°)=ε/̵͇̿̿/’̿’̿submit ̿ ̿</b-button>
            </section>
        </div>
    </article>
</template>

<script>
import { api } from '@/utils';

export default {
    data(){
        return {
            fromDate: new Date(),
            toDate: new Date(),
            email: '',
            organisation: '',
            only_return: false
        }
    },
    methods: {
        async submit() {
            const data = {
                from_date: this.fromDate,
                to_date: this.toDate,
                email: this.email,
            }
            if (this.organisation !== '') {
                data.organisation = this.organisation
            }

            if (this.only_return === true) {
                data.only_return = this.only_return
            }

            if (!data.email) {
                this.$buefy.toast.open({
                    duration: 3000,
                    message: `Email verplicht`,
                    position: 'is-top',
                    type: 'is-danger'
                })
                return
            }
            if (data.to_date < data.from_date) {
                this.$buefy.toast.open({
                    duration: 3000,
                    message: `To date mag niet kleiner zijn dan from date`,
                    position: 'is-top',
                    type: 'is-danger'
                })
                return
            }
            await api.createLabelDump(data)
        },
        cleareDate(type='from'){
            if(type ==='from')
                this.fromDate = new Date()
            
            else if(type ==='to') 
                this.toDate = new Date()
        }
    }
}
</script>